import Vue from 'vue'
import bugsnag from '@bugsnag/js'
import bugsnagVue from '@bugsnag/plugin-vue'

const bugsnagClient = bugsnag('{"apiKey":"c900f0465574693a6febe44018e4c604","notifyReleaseStages":["production","staging"]}')
bugsnagClient.use(bugsnagVue, Vue)

// eslint-disable-next-line no-unused-vars
export default function({ app }, inject) {
  inject('bugsnag', bugsnagClient)
}
